
.cookie-options {
  margin-top: 1.5rem;
}

.cookie-option {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;

  .help {
    margin-top: 0.5rem;
    color: #666;
  }
}

.modal-card-foot {
  justify-content: flex-end;
  gap: 1rem;
}
